@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  width:100%;
  height:100vh;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

a:hover{
  text-decoration: underline;
}

h2 {
  text-transform: uppercase;
  border-bottom: 1px solid #FF0000;
  color: #FF0000;
}

img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

td{
  padding: 10px;
  border-bottom: 1px solid #CCC;
}

tr:hover{
  background: #f2f2f2;
}

th{
  padding: 10px;
  background: #ff3333;
  color: #FFF;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #ff3333;
  color: #FFF;
}
